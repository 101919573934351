import { gql } from "@apollo/client";
import { initializeApollo } from "../apollo-client";

export const getSuggestion = async function (suggestionQuery) {
  const client = initializeApollo();
  return await client.query({
    query: gql`
            query suggestion {
                suggestion(input: "${suggestionQuery}") {
                    name
                }
            }
        `,
  });
};

export const getStateList = async function () {
  const client = initializeApollo();
  return await client.query({
    query: gql`
      query stateList {
        state {
          states(has_listing: true) {
            state_id
            state_name
            island
            slug
          }
        }
      }
    `,
  });
};
