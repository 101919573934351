import React, { useEffect, useState } from "react";
import { getStateList } from "../../../action/suggestion";
import { useRouter } from "next/router";
import { useStateContext } from "../../../context";

const DropdownSearch = ({ type }) => {
  const router = useRouter();

  const [state] = useStateContext();
  const [stateData, setStateData] = useState([]);

  useEffect(async () => {
    const {
      data: {
        state: { states },
      },
    } = await getStateList();
    setStateData(states);
  }, []);

  const handleRouterParam = (value) => {
    router.query.state = value;

    let items = JSON.parse(localStorage.getItem("lastSearched")) || [];
    items.unshift(value);

    localStorage.setItem("lastSearched", JSON.stringify(items));

    router.query.type = state.type === "" ? type : state.type;
    router.query.property = state.property === "" ? null : state.property;
    router.query.minPrice = state.minPrice === 0 ? null : state.minPrice;
    router.query.maxPrice = state.maxPrice === 0 ? null : state.maxPrice;
    router.query.bedroom = state.bed === 0 ? null : state.bed;
    router.query.bathroom = state.bath === 0 ? null : state.bath;
    router.query.floor = state.floor === 0 ? null : state.floor;
    router.query.minBuild = state.minBuild === 0 ? null : state.minBuild;
    router.query.maxBuild = state.maxBuild === 0 ? null : state.maxBuild;
    router.query.districtName =
      state.districtName === "" ? null : state.districtName;
    var str = "";
    for (var key in router.query) {
      if (router.query[key] !== null) {
        if (key !== "slug") {
          if (str != "") {
            str += "&";
          }
          str += key + "=" + encodeURIComponent(router.query[key]);
        }
      }
    }

    router.push(`/listing?${str}`);
  };

  return (
    <select
      className="form-select rounded-pill border-info border-2"
      onChange={(e) => handleRouterParam(e.target.value)}
    >
      <option selected>Provinsi</option>
      {stateData.map((state, i) => (
        <option value={state.state_name} key={i}>
          {state.state_name}
        </option>
      ))}
    </select>
  );
};

export default DropdownSearch;
