import React from "react";

const extractText = (str, query) => {
  const n = str.toUpperCase();
  const q = query.toUpperCase();
  const x = n.indexOf(q);
  if (!q || x === -1) {
    return { founded: str }; // bail early
  }
  const l = q.length;

  return {
    front: str.substr(0, x),
    founded: str.substr(x, l),
    ended: str.substr(x + l),
  };
};

const RecomendationItemText = (props) => {
  const { value, currentInput, onClick } = props;
  const explodedText = extractText(value, currentInput);

  return (
    <>
      <div onClick={onClick}>
        <b className="text-secondary-recommended">{explodedText.front}</b>
        {explodedText.founded}
        <b className="text-secondary-recommended">{explodedText.ended}</b>
      </div>
    </>
  );
};

export default RecomendationItemText;
