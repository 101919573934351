import React from "react";
import {sendFeedback} from "../../../action/sendFeedback";
import {TRACK_LISTING_WISHLIST} from "../../../utils/trackEvent";
import classNames from "classnames";
import dynamic from "next/dynamic";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {useRouter} from "next/router";
import {useStateContext} from "../../../context";
import {useMutation} from "@apollo/client";
import {CREATE_NOTIFICATION, CREATE_WISHLIST, DELETE_WISHLIST} from "../../../action/user";
import {toast} from "react-toastify";

const HeartIcon = dynamic(() => import("@heroicons/react/outline/HeartIcon"), {
    ssr: false,
});
const HeartSolidIcon = dynamic(
    () => import("@heroicons/react/solid/HeartIcon"),
    {ssr: false}
);

const WishListButton = ({listingId, secondary, listingUser}) => {
    const router = useRouter();

    const [state, useState] = useStateContext();

    const user = state.user;

    const isLiked = user?.wishlists?.some((wishlist) => wishlist.id == listingId);

    const [createWishList, {loading: creating}] = useMutation(CREATE_WISHLIST, {
        onError: (error) => {
            toast(error.message, {type: toast.TYPE.ERROR});
        },
        onCompleted() {
            toast("Listing berhasil ditambah kedalam daftar permintaan anda", {
                type: toast.TYPE.SUCCESS,
            });
        },
        update(cache) {
            cache.modify({
                id: cache.identify({
                    __ref: `User:${user.id}`,
                }),
                fields: {
                    wishlists(existing) {
                        const currentWistList = existing?.slice() || [];

                        return currentWistList.push({__ref: `Listing:${listingId}`});
                    },
                },
            });
        },
    });

    const [createNotif, {loading: notif}] = useMutation(CREATE_NOTIFICATION, {
        onError: (error) => {
            toast(error.message, {type: toast.TYPE.ERROR})
        },
        onCompleted() {
            console.log('success')
        }
    })

    const [deleteWishList, {loading: deleting}] = useMutation(DELETE_WISHLIST, {
        onError: (error) => {
            toast(error.message, {type: toast.TYPE.ERROR});
        },
        onCompleted() {
            toast("Listing berhasil dihapus dari daftar permintaan anda", {
                type: toast.TYPE.SUCCESS,
            });
        },
        update(cache) {
            cache.modify({
                id: cache.identify({
                    __ref: `User:${user.id}`,
                }),
                fields: {
                    wishlists(existing) {
                        const currentWistList = existing?.slice() || [];

                        return currentWistList.filter(
                            (wish) => wish.__ref != `Listing:${listingId}`
                        );
                    },
                },
            });
        },
    });

    const buttonCls = classNames("ms-2 position-relative pointer", {
        "text-secondary ": !secondary && !isLiked,
        "text-white": secondary && !isLiked,
        "text-danger": isLiked,
    });

    const favMethod = async () => {
        if (user?.id) {
            TRACK_LISTING_WISHLIST(listingId, {
                from: "listing-detail",
            });
            await sendFeedback({
                userId: user?.id?.toString(),
                type: "LIKE",
                id: listingId,
            });

            if (!isLiked) {
                createWishList({
                    variables: {
                        listingId,
                    },
                });
                createNotif({
                    variables: {
                        input: {
                            listing_id: listingId,
                            type: 'like listing',
                            fcm_id: listingUser.fcm_token ?? null,
                            message_to_send: `Seseorang Menyukai Listing Anda: ${user.first_name} ${user.last_name}`
                        }
                    }
                })
            } else {
                deleteWishList({
                    variables: {
                        listingId,
                    },
                });
                // createNotif({
                //     variables: {
                //         input: {
                //             listing_id: listingId,
                //             type: 'like listing',
                //             fcm_id: listingUser.fcm_token ?? null,
                //             message_to_send: `Seseorang Batal Menyukai Listing Anda: ${user.first_name} ${user.last_name}`
                //         }
                //     }
                // })
            }
        } else {
            // router.push("/login?continue=" + encodeURIComponent(router.asPath));
            useState({...state, loginModal: true});
        }
    };

    const loading = creating || deleting;

    return (
        <span
            onClick={favMethod}
            variant="link"
            className={buttonCls}
            size="sm"
            disabled={loading}
            style={{cursor: "pointer"}}
        >
      {loading && (
          <div
              className="d-flex align-items-center justify-content-center position-absolute top-0 start-0 end-0 bottom-0">
              <Spinner animation="border" size="sm"/>
          </div>
      )}
            {isLiked ? (
                <HeartSolidIcon width="30px" height="30px"/>
            ) : (
                <HeartIcon width="30px" height="30px"/>
            )}
    </span>
    );
};

export default WishListButton;
