import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { getSuggestion } from "../../../action/suggestion";
import {
  TRACK_AUTOCOMPLETE_CLICK,
  TRACK_SEARCH_SUMBIT,
} from "../../../utils/trackEvent";
import RecomendationItemText from "../HeaderSearch/RecomendationItemText";
import { useStateContext } from "../../../context";
import DropdownSearch from "../DropdownSearch";

const SearchListingBar = (props) => {
  const router = useRouter();
  const isSearchPage = router.pathname.includes("/cari");
  const { search, slug } = router.query;

  const searchPageKeyword = slug?.split("-").slice(1).join(" ");

  const [state] = useStateContext();

  const [searchInputValue, setSearchInputValue] = useState(
    search || searchPageKeyword || ""
  );
  const [inputValue, setInputValue] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [tab, setTab] = useState(0);

  const debouncedSearch = useDebounce(inputValue, 100);

  // useEffect(async () => {
  //   const {
  //     data: { suggestion },
  //   } = await getSuggestion(inputValue);
  //   setSuggestionList(suggestion);
  // }, []);

  useEffect(async () => {
    const {
      data: { suggestion },
    } = await getSuggestion(debouncedSearch);
    setSuggestionList(suggestion);
  }, [debouncedSearch]);

  const onInputChange = async (e) => {
    if (e.target.value.length > 0) {
      setInputValue(e.target.value);
      setSearchInputValue(e.target.value);
    } else {
      setSuggestionList([]);
      setInputValue(e.target.value);
      setSearchInputValue(e.target.value);
    }
  };

  const handleRouterParam = (value) => {
    if (!isSearchPage) {
      router.query.search = value;
    }
    router.query.type = state.type === "" ? null : state.type;
    router.query.property = state.property === "" ? null : state.property;
    router.query.minPrice = state.minPrice === 0 ? null : state.minPrice;
    router.query.maxPrice = state.maxPrice === 0 ? null : state.maxPrice;
    router.query.bedroom = state.bed === 0 ? null : state.bed;
    router.query.bathroom = state.bath === 0 ? null : state.bath;
    router.query.floor = state.floor === 0 ? null : state.floor;
    router.query.minBuild = state.minBuild === 0 ? null : state.minBuild;
    router.query.maxBuild = state.maxBuild === 0 ? null : state.maxBuild;
    router.query.districtName =
      state.districtName === "" ? null : state.districtName;
    var str = "";
    for (var key in router.query) {
      if (router.query[key] !== null) {
        if (key !== "slug") {
          if (str != "") {
            str += "&";
          }
          str += key + "=" + encodeURIComponent(router.query[key]);
        }
      }
    }
    if (isSearchPage) {
      router.push(
        `/cari-${searchInputValue.split(" ").join("-")}${
          str !== "" ? "?" + str : ""
        }`
      );
    } else {
      router.push(`/listing?${str}`);
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const value = inputValue;

    TRACK_SEARCH_SUMBIT(value, {
      from: "home",
    });
    setInputValue("");
    setSearchInputValue(value);
    handleRouterParam(value);
  };

  const onSuggestionClick = (value) => {
    TRACK_AUTOCOMPLETE_CLICK(value, {
      from: "home",
    });
    setInputValue("");
    setSearchInputValue(value);
    handleRouterParam(value);
  };

  return (
    <div>
      <form onSubmit={handleOnSubmit}>
        {props.isLanding ? (
          <div>
            <ul class="nav nav-tabs border-bottom-0">
              {["Jual", "Sewa"].map((type, i) => (
                <li class="nav-item" onClick={() => setTab(i)}>
                  <div
                    class={`nav-link cursor-pointer ${
                      tab === i
                        ? "active text-primary fw-bold border-end-0 border-start-0 border-end-0 border-bottom-0 border-2 border-primary"
                        : "bg-secondary bg-opacity-10"
                    }`}
                  >
                    <p
                      className={`d-none d-md-block mb-0 ${
                        tab === i ? "text-primary" : "text-secondary"
                      }`}
                    >
                      {type}
                    </p>
                    <p
                      className={`d-block d-md-none mb-0 ${
                        tab === i ? "text-primary" : "text-white"
                      }`}
                    >
                      {type}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            <div className="bg-light rounded-bottom rounded-end p-3">
              <DropdownSearch type={tab + 1} />
            </div>
          </div>
        ) : (
          <div className="d-flex gap-2">
            <input
              id="search-input"
              className="form-control"
              type="text"
              name="search"
              onChange={onInputChange}
              ref={props.inputEl}
              value={searchInputValue}
              autoComplete="off"
              placeholder="Cari di Rumahdewi"
            />
            <button type="submit" className="btn btn-primary">
              Cari
            </button>
          </div>
        )}
      </form>
      <ul className="list-group position-absolute" style={{ zIndex: 99 }}>
        {suggestionList.map(function (row) {
          return (
            <li
              className="list-group-item recommended-item"
              key={`recommendation-${row.name}`}
              style={{ cursor: "pointer" }}
            >
              <RecomendationItemText
                value={row.name}
                currentInput={inputValue}
                onClick={() => {
                  onSuggestionClick(row.name);
                }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchListingBar;

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
